<template>
  <v-container id="users-list" fluid tag="section">
    <v-layout row wrap>
      <v-col cols="12">
        <!-- <base-material-card color="primary" outlined> -->
        <v-app-bar flat color="white">
          <v-spacer></v-spacer>
          <!-- <curso></curso> -->
        </v-app-bar>
        <v-app-bar flat color="white">
          <v-text-field flat solo prepend-icon="mdi-magnify" placeholder="Digite algo" v-model="search" hide-details
            class=""></v-text-field>
          <v-btn icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </v-app-bar>
        <v-divider></v-divider>
        <!-- <v-card-text class="pa-0"> -->

        <v-data-table flat outlined no-results-text="Nenhum resultado encontrado" :headers="complex.headers"
          :search="search" :items="items" :footer-props="{
            'items-per-page-options': [
              10,
              25,
              50,
              { text: 'Todos', value: -1 },
            ],
          }" class="elevation-1" item-key="name" show-select v-model="complex.selected">
          <template slot="item" slot-scope="props">
            <tr :class="{ 'grey--text': props.item.disabled }">
              <td>
                <v-checkbox primary hide-details v-model="props.selected"></v-checkbox>
              </td>
              <td>{{ props.item.displayName }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.phoneNumber }}</td>
              <td>{{ getIsAdmin(props.item) }}</td>
              <td>{{ props.item.matricula }}</td>
              <td>{{ props.item.dataMatricula | formatDate("DD/MM/Y") }}</td>
              <td>{{ getDataLimite(props.item) | formatDate("DD/MM/Y") }}</td>
              <td>
                <v-btn depressed title="Desmatricular" icon fab dark color="green" small
                  @click.native="desmatricular(props.item)">
                  <v-icon>mdi-account-cancel-outline</v-icon>
                </v-btn>
                <v-btn depressed title="Renovar Matrícula" icon fab dark color="primary" small>
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

              </td>
            </tr>
          </template>
        </v-data-table>
        <!-- </v-card-text> -->
        <!-- </base-material-card> -->
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import toastr from "toastr";
// import {admin} from "firebase-admin";
import { db } from "@/db";

// Utilities
import { mapState } from "vuex";
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");
export default {
  components: {
    Curso: () => import("@/views/dashboard/components/core/Curso"),
    MatriculaLote: () => import("@/views/dashboard/components/core/MatriculaLote")
  },
  firebase () {
    let ref = "/cursos/" + this.$route.params.curso + "/matriculados";
    // let url = "/cursos/" + this.$route.params.curso + "/aulas/" + this.$route.params.id;
    // let ref2 = db.ref(url)
    let curso = "/cursos/" + this.$route.params.curso;
    return {
      items: db.ref(ref),
      curso: db.ref(curso),
      //   ementa: db.ref(ementa),
    };
  },
  data () {
    return {
      dialog: false,
      search: "",
      items: [],
      complex: {
        selected: [],
        headers: [
          {
            text: "Nome",
            value: "displayName",
          },
          {
            text: "E-mail",
            value: "email",
          },
          {
            text: "Telefone",
            value: "phoneNumber",
          },
          {
            text: "Função",
            value: "isAdmin",
          },
          {
            text: "Matrícula",
            value: "matricula",
          },
          {
            text: "Data Matrícula",
            value: "dataMatricula",
          },
          {
            text: "Data Limite",
            value: "dataLimite",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    getDataLimite (item) {
      const dataMatricula = new Date(item.dataMatricula);
      const dataMatriculaLimite = new Date(item.dataLimite);
      const dataLimite = new Date(this.curso.dataLimite);
      const permanencia = parseInt(this.curso.permanencia);

      let novaDataLimite;
      if (!isNaN(dataMatriculaLimite)) {
        novaDataLimite = dataMatriculaLimite;
      } else if (!isNaN(dataMatricula.getTime())) {
        novaDataLimite = new Date(dataMatricula);
        novaDataLimite.setDate(novaDataLimite.getDate() + permanencia * 7);
      } else {
        novaDataLimite = dataLimite;
      }

      return novaDataLimite;
    },
    desmatricular (user) {
      const refDesmatricularUser =
        "/users/" + user.uid + "/cursos/" + this.$route.params.curso;
      const refDesmatricularCurso =
        "/cursos/" + this.$route.params.curso + "/matriculados/" + user.uid;

      db.ref(refDesmatricularUser)
        .remove()
        .then(() => {
          db.ref(refDesmatricularCurso)
            .remove()
            .then(() => {
              // Update successful.
              toastr.success('Aluno desmatriculado com sucesso!', "Andressa Migueis");
              // this.aulas = [];
            })
            .catch((error) => {
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Andressa Migueis"
              );
            });
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Andressa Migueis"
          );
        });
    },
    getLabels (headers) {
      let label = {}
      console.log(headers)
      headers.forEach(function (e) {
        if (e.value) {
          label[e.value] = { title: e.text }
        }

      });
      return label
    },
    editar (user) {
      this.userEdit = user;
      this.dialogUser = true;
    },
    resetPassword (user) {
      toastr.info(
        "Solicitando redefinição de senha para: " +
        user.displayName +
        ". Aguarde ...",
        "Confabular"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/sendPasswordResetLink/" + user.email)
        .then((response) => {
          toastr.success(
            "E-mail com redefinição de senha para o usuário: " +
            user.displayName +
            " enviado com sucesso!",
            "Confabular"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "Confabular"
          );
        });
    },
    desativar (item) {
      toastr.info(
        "Desativando o curso: " + item.titulo + ". Aguarde ...",
        "Confabular"
      );

    },
    ativar (user) {
      toastr.info(
        "Ativando o cadastro de: " + user.displayName + ". Aguarde ...",
        "Confabular"
      );
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.$store.state.user.refreshToken;
      // console.log(newUser)
      this.$http
        .get("/enableUser/" + user.uid)
        .then((response) => {
          toastr.success(
            "Usuário: " + user.displayName + " ativado com sucesso!",
            "Confabular"
          );
          user.dialog = false;
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.error,
            "Confabular"
          );
        });
    },
    getNumMatriculados (curso) {
      if (curso && curso.matriculados) {
        return Object.keys(curso.matriculados).length
      } else {
        return 0
      }
    },
    getIsAdmin (item) {
      let retorno = "Aluno";
      //console.log(item)
      if (item.isAdmin) {
        retorno = "Administrador";
      }
      return retorno;
    }
  },
  computed: {
    ...mapState(["user"]),
    userEdit: {
      get () {
        return this.$store.state.userEdit;
      },
      set (val) {
        this.$store.commit("SET_USER_EDIT", val);
      },
    },
    dialogUser: {
      get () {
        return this.$store.state.dialogUser;
      },
      set (val) {
        this.$store.commit("SET_DIALOG_USER", val);
      },
    },
  },
  created () {
    /*admin
      .auth()
      .getUsers()
      .then((getUsersResult) => {
        console.log("Successfully fetched user data:");
        getUsersResult.users.forEach((userRecord) => {
          console.log(userRecord);
        });

        console.log("Unable to find users corresponding to these identifiers:");
        getUsersResult.notFound.forEach((userIdentifier) => {
          console.log(userIdentifier);
        });
      })
      .catch((error) => {
        console.log("Error fetching user data:", error);
      });*/
  },
  updated () {
    // console.log(this.user);
  },
  filters: {
    formatNumber (value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },
    formatDate (value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  }
}
</script>